<template>
  <div class="compare-table view">
    <app-header :title="'ItemDump Viewer'"/>
    <progress-linear v-if="loading" :indeterminate="true"/>

    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{ __('common.total') }}:</div>
        <div class="control-bar__info-value">{{ 0 }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="itembankUri"
          :label="'itembank uri'"
          max-length="255"
          :hide-details="true"
          @keyup:enter="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="main-content-padding">
      <div class="layout">
        <div class="flex xs3">
          <div class="side-nav tile scroll-horizontal-overflow">
            <v-jstree
                v-if="keyTree.length > 0"
                :data="keyTree"
                :size="'large'"
                :text-field-name="'key'"
                :value-field-name="'key'"
                :children-field-name="'children'"
                @item-click="keyClicked"
            >
            </v-jstree>
          </div>
        </div>
        <div class="flex xs9">
          <div class="layout layout--wrap">
            <div class="flex xs12 sm6 md4 mt" v-for="item in filteredYamlItems">
              <div class="tile tile--no-padding">
                <table class="object-table">
                  <tr v-for="propertyKey in Object.keys(item)">
                    <td>{{ propertyKey }}</td>
                    <td>{{ item[propertyKey] }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VJstree from 'vue-jstree';
import AppHeader from '../../../components/AppHeader';
import { notificationsMixin } from '../../../mixins/notifications';
import { localizationMixin } from '../../../mixins/localization';
import { paginationMixin } from "../../../mixins/pagination";
import { pageTitleMixin } from "../../../mixins/pageTitle";
import jsyaml from 'js-yaml';
import apiSecured from "../../../api/secured";

export default {
  name: 'ItemdumpViewer',
  mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
  components: {
    VJstree,
    AppHeader,
  },
  data: () => ({
    loading: false,
    yamlItems: [],
    keyTree: [],
    parents: [],
    parent: null,
    itembankUri: 'https://cdn.hyperion-corporation.de/itembanks/4b3dc20cb6d51436a8df1c2aac72a81bbcef79cbd64667635817cb3be8d62ac2.json',
    itembank: null,
  }),
  computed: {
    filteredYamlItems() {
      if (this.parent === null) {
        return [];
      }
      return this.yamlItems.filter(yamlItem => yamlItem.parent === this.parent);
    }
  },
  methods: {
    keyClicked(vm, model, event) {
      this.parent = model.key;
    },
    getChildren(key) {
      const foundItems = this.yamlItems.filter(yamlItem => yamlItem.parent && yamlItem.parent === key);
      return foundItems.map(foundItem => {
        return {
          key: foundItem.key,
          children: this.getChildren(foundItem.key),
        };
      });
    },
    refreshDelayed(delay) {
      setTimeout(this.refresh, delay);
    },
    async refresh() {
      apiSecured.get(this.itembankUri).then(async (res) => {
        this.itembank = res.data;
        this.loading = false;

        const yamlItems = jsyaml.load(this.itembank.items, { json: true });
        this.yamlItems = Object.keys(yamlItems).map(key => {
          return { key, ...yamlItems[key] };
        })
        const rootKeys = this.yamlItems.filter(yamlItem => !yamlItem.parent).map(yamlItem => yamlItem.key);

        this.keyTree = rootKeys.map(rootKey => {
          return {
            key: rootKey,
            children: this.getChildren(rootKey),
          }
        });
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
  },
  created() {
    this.setPageTitle('ItemDump Viewer');
    this.refresh();
  },
}
</script>
